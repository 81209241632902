
import { Component, Vue } from 'vue-property-decorator'
import * as Echarts from 'echarts'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'
import { PolygonPathDetail, DicInfo } from '@/types/common'

@Component
export default class DetailMap extends Vue {
  private onlineImg = require('@/assets/icon/bracelet/online.svg')
  private onlineImgCursor = require('@/assets/icon/bracelet/online2.svg')
  private warningImg = require('@/assets/icon/bracelet/sos.svg')
  private warningImgCursor = require('@/assets/icon/bracelet/sos2.svg')
  private projectPolygon: Array<AMap.Polygon> = []
  private map: AMap.Map | any = null
  private markers: AMap.Marker[] = []

  private drawer = false
  private drawerData = this.drawerDataBak
  private sexList: Array<DicInfo> = []

  get projectId () {
    return this.$route.params.projectId as string || ''
  }

  get drawerDataBak () {
    return {
      workerId: '', // 工人id
      workerName: '', // 工人名称
      deviceName: '',
      deviceNumber: '',
      longitude: '',
      latitude: '',
      collectTime: '',
      dutyStatus: '', // 在岗状态
      sosStatus: '', // sos状态
      workerAge: '',
      workerSex: '',
      workerCard: '', // 身份证
      workerMobile: '',
      battery: '', // 电量
      batteryStatus: '', // 电量状态
      workerImg: '' // 照片地址
    }
  }

  created () {
    this.getLocation()
    this.getSexList()
  }

  // 绑定后重新获取数据
  activated () {
    this.getLocation()
  }

  // 页面离开
  destroyed () {
    if (this.map) {
      this.map.off('click', this.drawerClose())
      this.map.destroy()
    }
  }

  // 获取项目电子围栏
  getLocation () {
    this.$axios.get(this.$apis.project.selectYhProjectMapByProjectId, {
      projectId: this.projectId
    }).then((res) => {
      this.loadMap(res, res.locationList)
    })
  }

  getData () {
    this.$axios.get(this.$apis.recruitUse.selectYhWorkerInfoByPage, {
      projectId: this.projectId,
      deviceStatus: 1
    }).then(res => {
      // 打点
      this.drawMarkers(res.list || [])
    })
  }

  drawMarkers (list: Array<{ longitude: string; latitude: string; workerName: string; sosStatus: string }>) {
    this.map.remove(this.markers)
    this.markers = []
    list.map((item: { longitude: string; latitude: string; workerName: string; sosStatus: string }) => {
      const lnglat = [Number(item.longitude), Number(item.latitude)]
      const marker: AMap.Marker = new AMap.Marker({
        icon: new AMap.Icon({
          image: this.getIcon(item, false)
        }),
        offset: new AMap.Pixel(-18, -48),
        position: new AMap.LngLat(lnglat[0], lnglat[1]),
        label: {
          direction: 'top',
          content: '<div class="marker-label-content">' + item.workerName + '</div><div class="marker-label-sharp"></div>'
        },
        extData: item
      })
      marker.on('click', (e: any) => {
        this.clickMarker(e)
      })
      this.map.add(marker)
      this.markers.push(marker)
    })
  }

  // 性别
  getSexList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'workerSex' }).then(res => {
      this.sexList = res.workerSex || []
    })
  }

  getSexName (type: string) {
    const items = this.sexList.find((item: DicInfo) => type === item.dicCode) as DicInfo || { dicValue: '--' }
    return items.dicValue
  }

  // echarts图
  getChart () {
    this.$axios.get(this.$apis.recruitUse.selectYhStatusCount, {
      projectId: this.projectId
    }).then(res => {
      this.onlineEchart([res.offWorkerNum, res.onWorkerNum])
      this.healthyEchart([res.onSosNum, res.offSosNum])
    })
  }

  // 加载地图
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<PolygonPathDetail>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      this.$nextTick(() => {
        const map = new AMap.Map('map', {
          center: center,
          zoom: 11
        })
        // 点击关闭抽屉
        map.on('click', this.drawerClose)
        this.projectPolygon = drawPolygon(map, list || [], null, this.drawerClose)
        map.setFitView(this.projectPolygon)
        this.map = map
        this.getData()
        this.getChart()
      })
    })
  }

  // 点击marker
  clickMarker (e: any) {
    const detail = e.target.getExtData()
    // 判断点击的是否是当前抽屉的设备
    if (this.drawerData.workerId !== detail.workerId) {
      if (this.drawer) {
        this.drawerClose()
      }
      this.drawer = true
      this.drawerData = detail
      // 改变图标
      e.target.setOffset(new AMap.Pixel(-22, -64))
      e.target.setIcon(new AMap.Icon({
        image: this.getIcon(detail, true)
      }))
      e.target.setzIndex(13)
    }
  }

  // 清除mark
  clearMapMarks () {
    if (this.markers.length > 0) {
      this.map && this.map.remove(this.markers)
      this.markers = []
    }
  }

  // 抽屉关闭
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      // 恢复未点击状态图标
      const marker: AMap.Marker[] = this.markers.filter(item => item.getExtData().workerId === this.drawerData.workerId)
      if (marker) {
        this.map.remove(marker[0])
        marker[0].setOffset(new AMap.Pixel(-18, -48))
        marker[0].setIcon(this.getIcon(marker[0].getExtData(), false))
        marker[0].setzIndex(12)
        this.map.add(marker[0])
      }
      // 清空抽屉
      this.drawerData = this.drawerDataBak
    }
  }

  // 获取图标 bigShow:是否点击状态
  getIcon (data: { sosStatus: string }, bigShow: boolean) {
    const isSos = data.sosStatus === '2'
    return isSos ? bigShow ? this.warningImgCursor : this.warningImg : bigShow ? this.onlineImgCursor : this.onlineImg
  }

  // sos解除
  cancelSos () {
    this.$confirm('是否解除SOS报警?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      closeOnClickModal: false
    }).then(() => {
      this.$axios.post(this.$apis.recruitUse.updateYhWorkerSos, {
        workerId: this.drawerData.workerId
      }).then(() => {
        this.$message.success('解除成功')
        this.drawerClose()
        // 清除markers
        this.clearMapMarks()
        this.getData()
        this.getChart()
      })
    })
  }

  // 环形图
  onlineEchart (presentPie: Array<string>) {
    const pieEchart: any = Echarts.init(this.$refs.onlineEcharts as any)
    const option = {
      title: {
        text: '在岗监测',
        textStyle: {
          align: 'center',
          color: '#000',
          fontSize: 14,
          fontWeight: 'bold'
        },
        top: 20,
        left: 20
      },
      color: ['#5750EB', '#975FE4'],
      legend: {
        icon: 'circle',
        itemHeight: 6,
        show: true,
        itemGap: 15,
        right: 20,
        top: '45%',
        orient: 'vertical',
        textStyle: {
          fontSize: 14 // 字体大小
        },
        formatter: (item: string) => {
          return item === '在岗' ? `${item}  ${presentPie[0]}` : `${item}  ${presentPie[1]}`
        }
      },
      series: [{
        name: 'Line 1',
        type: 'pie',
        clockWise: true,
        radius: ['35%', '60%'],
        center: ['30%', '58%'],
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          }
        },
        hoverAnimation: false,
        data: [{
          name: '在岗',
          value: presentPie[0]
        }, {
          name: '脱岗',
          value: presentPie[1]
        }]
      }]
    }
    pieEchart.setOption(option)
  }

  // 环形图
  healthyEchart (healthPie: Array<string>) {
    const pieEchart: any = Echarts.init(this.$refs.healthyEcharts as any)
    const option = {
      title: {
        text: '状态监测',
        textStyle: {
          align: 'center',
          color: '#000',
          fontSize: 14,
          fontWeight: 'bold'
        },
        top: 20,
        left: 20
      },
      color: ['#5750EB', '#975FE4'],
      legend: {
        icon: 'circle',
        itemHeight: 6,
        show: true,
        itemGap: 15,
        right: 30,
        top: '45%',
        orient: 'vertical',
        textStyle: {
          fontSize: 14 // 字体大小
        },
        formatter: (item: string) => {
          return item === '正常' ? `${item}  ${healthPie[0]}` : `${item}  ${healthPie[1]}`
        }
      },
      series: [{
        name: 'Line 1',
        type: 'pie',
        clockWise: true,
        radius: ['35%', '60%'],
        center: ['30%', '58%'],
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          }
        },
        hoverAnimation: false,
        data: [{
          name: '正常',
          itemStyle: {
            normal: {
              color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(111, 178, 254, 1)'
              }, {
                offset: 1,
                color: 'rgba(27, 133, 255, 1)'
              }])
            }
          },
          value: healthPie[0]
        }, {
          name: '异常',
          itemStyle: {
            normal: {
              color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(255, 52, 27, 1)'
              }, {
                offset: 1,
                color: 'rgba(255, 109, 106, 1)'
              }])
            }
          },
          value: healthPie[1]
        }]
      }]
    }
    pieEchart.setOption(option)
  }

  // 轨迹回放
  onPlayback () {
    this.$router.push({
      name: 'recruitUseMapPlayback',
      params: { workerId: this.drawerData.workerId, projectId: this.projectId }
    })
  }

  getProgressPer (detail: { battery: string }) {
    return () => {
      if (detail.battery) {
        return `${detail.battery}%`
      }
      return '--'
    }
  }
}
